.poppins-thin {
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.poppins-extralight {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: normal;
}

.poppins-light {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.poppins-regular {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.poppins-medium {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.poppins-semibold {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.poppins-bold {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.poppins-extrabold {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-style: normal;
}

.poppins-black {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.poppins-thin-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.poppins-extralight-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: italic;
}

.poppins-light-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.poppins-regular-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.poppins-medium-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.poppins-semibold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: italic;
}

.poppins-bold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.poppins-extrabold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-style: italic;
}

.poppins-black-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-style: italic;
}

.crimson-pro-thin {
  font-family: "Crimson Pro", serif;
  font-optical-sizing: auto;
  font-weight: 200;
  font-style: normal;
}

.crimson-pro-extralight {
  font-family: "Crimson Pro", serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
}

.crimson-pro-light {
  font-family: "Crimson Pro", serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.crimson-pro-regular {
  font-family: "Crimson Pro", serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
}

.crimson-pro-medium {
  font-family: "Crimson Pro", serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
}

.crimson-pro-semibold {
  font-family: "Crimson Pro", serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
}

.crimson-pro-bold {
  font-family: "Crimson Pro", serif;
  font-optical-sizing: auto;
  font-weight: 800;
  font-style: normal;
}

.crimson-pro-extrabold {
  font-family: "Crimson Pro", serif;
  font-optical-sizing: auto;
  font-weight: 900;
  font-style: normal;
}

.crimson-pro-thin-italic {
  font-family: "Crimson Pro", serif;
  font-optical-sizing: auto;
  font-weight: 200;
  font-style: italic;
}

.crimson-pro-extralight-italic {
  font-family: "Crimson Pro", serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: italic;
}

.crimson-pro-light-italic {
  font-family: "Crimson Pro", serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: italic;
}

.crimson-pro-regular-italic {
  font-family: "Crimson Pro", serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: italic;
}

.crimson-pro-medium-italic {
  font-family: "Crimson Pro", serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: italic;
}

.crimson-pro-semibold-italic {
  font-family: "Crimson Pro", serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: italic;
}

.crimson-pro-bold-italic {
  font-family: "Crimson Pro", serif;
  font-optical-sizing: auto;
  font-weight: 800;
  font-style: italic;
}

.crimson-pro-extrabold-italic {
  font-family: "Crimson Pro", serif;
  font-optical-sizing: auto;
  font-weight: 900;
  font-style: italic;
}
