body,
#root {
  margin: 0;
  padding: 0;
  font-family: "Poppins";
  color: #fff;
  /* background-color: #fafafa; */
  /* background: url(../img/bg.png) top center no-repeat, #040404;
  background-size: cover;
  background-attachment: fixed; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  --main-color: #0027ca;
}

footer {
  margin-top: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Enable to hide the iframe */
/* iframe {
  display: none;
} */

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  font-size: 14px;
  color: lightgrey;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-size: 14px;
  color: lightgrey;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  font-size: 14px;
  color: lightgrey;
}
