.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #444444;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #d63370;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* User defined */
.mt-custom {
  margin-top: 12rem; /* Adjust the value as needed */
}

/* Login, Register and Select Category Pages */
.app-category-container,
.app-login-container,
.app-register-container {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 600px;
}
.app-job-create-CategoryCard,
.app-category-sm-container,
.app-register-sm-container,
.app-login-sm-container {
  /* width: 100%; */
  width: auto;
  /* margin: 0 auto; */
  background: url(../img/login-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0px -75px;
}

.app-category-card,
.app-register-card,
.app-login-card {
  width: 400px;
  max-height: 600px;
  margin: 0 auto;
  padding: 2rem 2rem;
  border-radius: 16px;
  border-color: #fff;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  background-image: url(../img/login-bg.png);
  background-repeat: no-repeat;
  background-position: 0px -20px;
}

.app-login-influencer-back-button {
  color: #232323;
  background-color: transparent !important;
  border: none;
}
.app-login-influencer-back-button:hover {
  color: #232323;
  background-color: transparent !important;
  border: none;
}

.app-login-influencer-timer {
  color: #232323;
  font-size: 14px;
}

.app-login-influencer-placeholder input {
  background-color: #ffffff;
  border-radius: 8px;
}
.app-home-h3,
.app-category-h2,
.app-home-drivebussinessgrowth,
.app-register-h2,
.app-login-h2 {
  color: #232323;
  font-size: 32px;
}
.app-home-p,
.app-category-p,
.app-register-p,
.app-login-p {
  color: #787878;
  font-size: 14px;
}

.nav-tabs,
.app-login-influencer-login-screen,
#login,
#select-category,
.app-job-create-CategoryCard {
  font-size: 14px;
  border-bottom: none;
  border-radius: 30px;
  padding: 8px 12px 8px 12px;
  border: none;
}

.nav-tabs .nav-link,
.app-login-influencer-login-screen,
#login,
#select-category {
  margin-right: 8px;
  color: #444444;
  font-size: 14px;
  border-radius: 30px;
}

.nav-tabs .nav-link.active,
.app-login-influencer-login-screen {
  color: #fff;
  background-color: #444444;
  font-size: 14px;
  border-radius: 30px;
  border-bottom: none;
}
.app-home-footer-container,
.app-jobfeed-footer-container {
  background-color: rgba(68, 68, 68, 1);
  margin-top: 200px;
}
.app-jobfeed-collaboration-active {
  background-color: rgba(68, 68, 68, 1);
  color: white;
  border-radius: 30px;
  padding: 4px 8px 4px 8px;
  cursor: pointer;
}
.app-jobfeed-coming {
  margin-bottom: -8px;
  justify-content: center;
  display: flex;
  margin-left: 130px;
  font-size: 12px;
  color: rgba(70, 176, 94, 1);
}

.app-jobfeed-commingsoon {
  background-color: rgba(236, 255, 240, 1);
}

.app-jobfeed-collaboration {
  color: rgba(68, 68, 68, 1);
  border-radius: 30px;
  padding: 4px 8px 4px 8px;
  border: 1px solid rgba(226, 226, 226, 1);
  cursor: pointer;
}
.nav-tabs .nav-link:hover {
  border-radius: 30px;
}
.app-login-influencer-login-screen:hover {
  background-color: #444444;
}
.app-login-influencer-verified,
.app-login-influencer-form-input,
.app-register-form-input,
.app-login-form-input {
  height: 48px;
  font-size: 14px;
  border-radius: 8px;
  border-color: #f5f5f5;
  background-color: #f5f5f5;
  color: #232323;
}
.app-setting-edit {
  max-width: 358px;
}

.app-register-password-eye,
.app-login-password-eye {
  color: #bfbfbf;
  font-size: 14px;
  background-color: #f5f5f5;
  border: none;
}
.app-login-job-create-selected {
  /* color: #bfbfbf; */
  font-size: 14px;
  background-color: #f5f5f5;
  border: none;
  height: 50px;
}
.app-home-mobile-footer-subheadings,
.app-login-link a {
  color: #232323;
  font-size: 12px;
  text-decoration: underline;
}
.app-profile-edit {
  text-decoration: underline;
  cursor: pointer;
}
.app-login-link a:hover {
  color: #232323;
  text-decoration: none;
}

.app-category-button,
.app-register-button,
.app-login-button {
  background: var(--main-color);
  width: 326px;
  height: 56px;
  border: 1px solid var(--main-color);
  border-radius: 8px;
  color: #fff;
}
.app-login-influencer-correctotp-input {
  border: 1px solid #63c261;
}

.app-login-incorrect-otp {
  /* font-size: 14px; */
  border: 1px solid #ec4d4d;
}
.app-login-incorrect-otp-input {
  font-size: 14px;
}
.app-login-check-button {
  background: var(--main-color);
  width: 56px;
  height: 56px;
  border: 1px solid var(--main-color);
  border-radius: 36px;
  color: #fff;
}

.app-register-footer,
.app-login-footer {
  color: #232323;
  font-size: 14px;
  text-align: center;
  font-weight: 400;
}

.app-register-footer a,
.app-login-footer a {
  font-weight: 600;
  text-decoration: underline;
  color: #232323;
}

.app-register-footer a:hover,
.app-login-footer a:hover {
  text-decoration: none;
}

/* Select Category */
.app-job-create-heading,
.app-select-category-menu-heading,
.app-profile-expert {
  font-size: 18px;
  color: #232323;
}
.app-profile-card {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 40px;
  background-color: transparent;
  background: linear-gradient(92.85deg, #fff2f7 0%, #f6f3ff 100%);
  width: 100%;
  border: none;
  /* margin-bottom: -40px; */
}
.app-profile-charge {
  color: #232323;
  background-color: #ffffff;
  opacity: 0.95;
}
.app-category {
  font-size: 14px;
  color: #636363;
}

.app-category input[type="checkbox"] {
  display: none;
}
.app-filter-checkbox input[type="checkbox"] {
  display: none;
}

.app-category input[type="checkbox"] + label::before {
  content: "";
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 10px;
  border: 1px solid #d63370;
  border-radius: 50%;
  vertical-align: middle;
  text-align: center; /* Add this line */
  line-height: 16px; /* Add this line */
}
.app-filter-checkbox input[type="checkbox"] + label::before {
  content: "";
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 5px;
  border: 1px solid rgba(191, 191, 191, 1);
  /* border-radius: 50%;  */
  border-radius: 4px;
  vertical-align: middle;
  text-align: center;
  line-height: 16px; /* Add this line */
}

.app-category input[type="checkbox"]:checked + label::before {
  content: "\2713";
  background-color: #d63370;
  color: #fff;
  vertical-align: middle;
  text-align: center; /* Add this line */
  line-height: 16px; /* Add this line */
}
.app-filter-checkbox {
  font-size: 12px;
  padding: 0%;
}
.app-filter-checkbox input[type="checkbox"] {
  display: none;
}
.app-filter-checkbox input[type="checkbox"]:checked + label::before {
  content: "\2713";
  background-color: #d63370;
  border: 1px solid #d63370;
  color: #fff;
  vertical-align: middle;
  text-align: center; /* Add this line */
  line-height: 16px; /* Add this line */
}
.app-filter-checkbox input[type="checkbox"] + label::before {
  content: "";
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 5px;
  border: 1px solid rgba(191, 191, 191, 1);
  /* border-radius: 50%;  */
  border-radius: 4px;
  vertical-align: middle;
  text-align: center;
  line-height: 16px; /* Add this line */
}

.app-filter-checkbox input[type="checkbox"]:checked + label::before {
  content: "\2713";
  background-color: #d63370;
  border: 1px solid #d63370;
  color: #fff;
  vertical-align: middle;
  text-align: center; /* Add this line */
  line-height: 16px; /* Add this line */
}

.app-scrollable-categories {
  font-size: 16px;
  height: 222px;
  overflow-y: auto;
}
.app-scrollable-categories::-webkit-scrollbar {
  width: 6px;
  /* background-color: #fff; */
  background-color: transparent;
}
.app-scrollable-categories::-webkit-scrollbar-thumb {
  background: #e2e2e2;
  border-radius: 20px;
}
.app-jobfeed-container-fluid {
  background-image: linear-gradient(180deg, #f6f3ff 0%, #fbe5ee 100%);
}
/* AppBar */
.app-bar-nav .navbar {
  display: flex;
  align-items: stretch;
}
.app-joblist-container {
  margin-left: 12px;
}
.app-bar-nav .offcanvas-body {
  display: flex;
  height: 56px;
}

.app-bar-nav .offcanvas-body .nav {
  height: 100%;
}

.app-bar-nav .nav-link {
  display: flex;
  align-items: center;
  color: #444444;
  font-size: 14px;
}

.app-bar-nav .nav-link.active {
  color: #d63370 !important;
  border-bottom: 2px solid #d63370;
}

.app-bar-nav .nav-link:hover {
  color: #000;
}

.app-bar-nav .navbar-toggler .navbar-brand {
  display: flex;
  justify-content: flex-start;
}

.app-bar-nav .navbar-toggler,
.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
  outline: none;
  border: none;
  box-shadow: none;
}
.app-influencerdetails-viewdet-applicants-card {
  border: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.app-influencerdetails-viewdet-applicants-card-working {
  background-image: linear-gradient(
    rgba(255, 242, 247, 1),
    rgba(246, 243, 255, 1)
  );
  padding-left: 2%;
  padding-right: 2%;
  border-radius: 4px;
}
.app-home-connect-desktop,
.app-home-h2,
.app-influencerdetails-applicants-select {
  font-size: 24px;
}

@media (min-width: 768px) {
  .app-bar-nav-items {
    display: flex;
    align-items: center;
  }

  .app-bar-nav-items .dropdown-menu {
    left: auto;
    right: 0;
  }
}

@media (max-width: 767px) {
  .app-bar-nav-items {
    display: flex;
    flex-direction: row;
  }

  .app-bar-nav-items .nav-link {
    margin-left: 10px;
  }

  .app-bar-nav-items .nav-link:last-child {
    margin-right: 0px;
  }

  .app-bar-nav-items .dropdown-menu {
    position: absolute;
    top: 100%;
    width: 100%;
    left: auto;
    right: 0;
  }
}

/* Filters */
.app-filter-container {
  color: #000;
}

.app-filter-box {
  padding: 0.5rem 1.5rem;
  border: 1px solid #f0f0f0;
  border-radius: 17px;
}
.app-home-footer-subheadings-span,
.app-filter-checkbox {
  font-size: 12px;
  padding: 0%;
}

.app-filter-search {
  font-size: 14px;
  border-radius: 8px;
  border-color: #f5f5f5;
  background-color: #f5f5f5;
  padding-left: 20px;
}

.app-filter-h4 {
  font-size: 12px;
  color: #a1a1a1;
}

/* Job List */
.app-job-list-search-bar {
  position: relative;
  width: 100%;
}

.app-job-list-bar {
  padding: 0.5rem 0.5rem;
  background-color: #f5f5f5;
  border-radius: 34px;
}

.app-job-list-search {
  font-size: 14px;
  border-radius: 34px;
  border-color: #fff;
  background-color: #fff;
  padding: 1%;
  padding-left: 30px;
  width: 100%;
}

.app-joblist-search-icon {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  color: rgba(99, 99, 99, 1);
}

.app-jobList-plus-icon {
  font-size: 20px;
  width: 40px;
  height: 40px;
  border-radius: 36px;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: rgba(0, 39, 202, 1);
}
.app-home-step-headiangs,
.app-jobfeed-blog-h1 {
  font-size: 20px;
}
.app-joblist-category-search-icon {
  margin-top: -40px;
  font-size: small;
  color: rgba(99, 99, 99, 1);
}

.app-job-list-bar-button {
  border-radius: 34px;
  background-color: #ffffff;
  color: #444444;
  border: 1px solid #f0f0f0;
}

.app-job-list-bar-button:hover {
  border-radius: 34px;
  background-color: #444444;
  color: #e8e7e7;
  border: 1px solid #444444;
}

.app-job-list-badge {
  background-color: #fff2f7 !important;
  border: 1px solid #d63370 !important;
  border-radius: 8px;
  font-size: 12px;
  color: #e94f89 !important;
}

.app-job-list-badge:hover,
.app-jobList-FilterCard-badge:hover {
  background-color: #fff2f7 !important;
  border: 1px solid #d63370;
  border-radius: 8px;
  color: #e94f89;
}

.app-job-list-card {
  background-color: #ffffff;
  border: 1px solid #f0f0f0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
}

.app-job-list-card-inactive {
  background-color: #d6d6d6;
  border: 1px solid #f0f0f0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
}

.app-job-list-author {
  font-size: 14px;
  color: #636363;
}
.app-home-our-seamless,
.app-profile-createdcard-h1 {
  font-size: 16px;
}

.app-job-list-title {
  font-size: 16px;
  color: #232323;
}

.app-job-list-content,
.app-job-list-category,
.app-profile-createdcard-h3 {
  font-size: 12px;
  color: #787878;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.app-job-list-details {
  font-size: 12px;
  background-color: #f5f5f5 !important;
  color: #444444 !important;
  display: inline-block;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  padding: 10px;
  color: #444444;
}
.app-home-details-link,
.app-job-list-details-link {
  font-size: 14px;
  text-decoration: none;
  color: var(--main-color);
  text-align: right;
}

.app-job-list-expired-badge {
  background-color: #f5f5f5 !important;
  border: 1px solid #a1a1a1;
  border-radius: 8px;
  padding-top: 4px;
  color: #a1a1a1;
}

.app-jobList-MobileFilter-badge {
  background-color: #fff2f7 !important;
  border: 1px solid #d63370 !important;
  border-radius: 8px;
  padding-top: 4px;
  color: #e94f89 !important;
}
.app-jobList-MobileFilter-badge:hover {
  background-color: #fff2f7 !important;
  border: 1px solid #d63370 !important;
  border-radius: 8px;
  padding-top: 4px;
  color: #e94f89 !important;
}
.app-jobList-FilterCard-badge {
  border: transparent;
  border-radius: 8px;
  height: 30px;
  padding-top: 3px;
  background-color: #ffffff;
  color: #232323;
}
.app-jobList-FilterCard-badge:hover {
  border: 1px solid #d63370 !important;
  border-radius: 8px;
  height: 30px;
  padding-top: 3px;
  background-color: #ffffff;
  color: #e94f89 !important;
}

.app-job-list-user-card {
  border: 1px solid #f0f0f0;
  border-radius: 8px;
  color: #232323;
  background-image: url(../img/login-bg.png);

  background-repeat: no-repeat;
  background-position: 0px -75px;
}

.app-job-list-user-card-name {
  font-size: 14px;
  color: #232323;
}
.app-influencerdetails-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.app-influencerdetails-appliedcard {
  color: #232323;
  border: none;
  width: 100%;
  max-width: 400px;
}
.app-job-list-user-card-contact {
  font-size: 12px;
  color: #787878;
}

.app-job-list-user-card-status {
  font-size: 12px;
  color: #46b05e;
}

.app-job-list-user-card-progress {
  width: 100%;
  height: 4px;
  background-color: #dbecdf;
}
.app-home-footer-heading,
.app-job-list-user-card-link {
  font-size: 14px;
}
.app-job-list-user-card-created {
  background-color: #f5f5f5 !important;
}

.app-job-list-user-card-details {
  font-size: 12px;
  color: #787878;
}

.app-home-text-color,
.app-job-list-sm-container,
.app-influencerdetails-applicants {
  color: #232323;
}
.app-profile-setting-mobile1 {
  max-width: 20px;
}
.app-profile-setting-mobile2 {
  max-width: 80%;
}
.app-profile-setting-mobile3 {
  max-width: 20px;
}

.app-job-list-sm-filter-container {
  overflow-x: auto;
  max-width: 450px;
}
.app-job-list-sm-filter-container::-webkit-scrollbar {
  display: none;
}
.app-home-footer-subheadings,
.app-joblist-heartclick {
  cursor: pointer;
}
.app-job-list-sm-filter-container2 {
  overflow-x: auto;
  max-width: 650px;
}
.app-job-list-sm-filter-container2::-webkit-scrollbar {
  display: none;
}

.app-jobList-FilterCards-overflow {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  max-width: 430px;
}

.app-jobList-FilterCards-overflow-sm {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  max-width: 300px;
}

.app-influencerjobpage-scroller-container::-webkit-scrollbar {
  display: none;
}

.app-influencerjobpage-scroller-cards {
  display: flex;
  flex-wrap: nowrap;
}

.app-influencerjobpage-scroller-container {
  overflow-x: auto;
}

.app-job-list-mobile-filter {
  border-radius: 16px;
  background-color: #ffffff;
}

.app-job-list-mobile-filter-card {
  font-family: "Poppins", sans-serif;
  color: #232323;
  background-color: white !important;
  border-radius: 30px;
  border: 1px solid #e2e2e2 !important;
}
.app-job-list-mobile-filter-card:hover {
  font-family: "Poppins", sans-serif;
  color: #232323;
  background-color: white;
  border-radius: 30px;
  border: 1px solid #e2e2e2;
}

.app-jobList-FiltersCards {
  height: 88px;
  background-color: #fff2f7;
  border-radius: 8px;
  font-size: 12px;
  padding: 6px, 8px, 6px, 8px;
}
.app-joblist-card-link {
  text-decoration: none;
}
.app-joblist-applied {
  font-size: 12px;
}
.app-jobList-FilterHeading {
  font-family: "Poppins", sans-serif;
}
.app-profile-createdcard-h3-date {
  /* background-color: #f5f5f5; */
  height: 30px;
  border-radius: 8px;
  padding-left: 6px 8px 6px 8px;
  margin-right: 3px;
}
.app-profile-createdcard-h3-viewdet {
  font-size: 14px;
  color: #0027ca;
}
.app-userprofile-instagram {
  border-radius: 16px;
  background-image: linear-gradient(
    to right,
    #ece7fb 34%,
    #f0f0f0 33%,
    #fff2f7 33%
  );
  height: 68px;
}
.app-userprofile-boost,
.app-userprofile-created-card {
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  height: 68px;
  cursor: pointer;
}
.app-userprofile-createdcard {
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  border: none;
}
.app-userprofile-card {
  border: none;
}
.app-profile-verticle-line {
  border-left: 1px solid #e2e2e2;
  height: 677px;
  margin-left: -12px;
}
.app-profile-createdcards-applied {
  font-size: 12px;
  color: #787878;
}
.app-profile-createdcards-btn1 {
  text-decoration: none;
  background-color: transparent !important;
  color: #232323;
  border: none;
  padding: 0%;
}
.app-profile-createdcards-btn1:hover {
  text-decoration: none;
  color: #232323;
  border: none;
  padding: 0%;
  cursor: pointer;
}
.images {
  margin-left: -15px;
}
.app-influencerjobpage-card1-h {
  font-size: 14px;
  color: #787878;
}
.app-influencerjobpage-cards {
  max-width: 304px;
  max-height: 183px;
}
.app-influencerjobpage-card1-h2,
.app-influencerjobpage-cards-req {
  font-size: 14px;
}
.app-influencerjobpage-influencer {
  font-size: 14px;
  color: #636363;
}
.app-influencerjobpage-req {
  font-size: 16px;
}
.app-home-container {
  color: #232323;
  /* background-image: url("../../../public/homebg_1.png"); */
  background-size: cover;
  background-attachment: fixed;
  /* margin-top: 20%; */
}
.app-home-container-h1 {
  font-size: 52px;
  color: rgba(35, 35, 35, 1);
  line-height: 44.45px;
}
.app-home-h1,
.app-jobfeed-container-h1 {
  font-family: crimson pro;
}
.app-jobfeed-photophone {
  max-width: 358px;
}
.app-home-step {
  max-width: 358px;
  max-height: 358px;
  background-color: rgba(246, 243, 255, 1);
  border-radius: 16px;
}
.app-home-step2 {
  max-width: 358px;
  max-height: 358px;
  background-color: rgba(255, 242, 247, 1);
  border-radius: 16px;
}
.scroll-container {
  display: flex;
  overflow-y: auto;
  padding-bottom: 50px;
}
.app-image-mob1,
.app-image-mob2,
.app-image-mob3 {
  margin-top: 60px;
  display: inline-block;
}

.app-image-mob1:hover,
.app-image-mob2:hover,
.app-image-mob3:hover {
  margin-top: 50px;
  margin-bottom: 10px;
}

.scroll-container::-webkit-scrollbar {
  display: none;
}
.app-home-container-image1 {
  width: 100%;
  height: auto;
}
.app-home-container-image3-mob {
  margin-top: 50px;
  width: 200px;
  height: auto;
}
/*

.app-home-container-image1-mob {
  margin-top: 50px;
  width: 200px; 
  height: auto; 
}
.app-home-container-image3-mob {
  margin-top: 50px;
  width: 200px; 
  height: auto; 
}
.app-home-container-image2-mob {
  margin-top: 50px;
  width: 200px; 
  height: auto; 
} */

.app-home-footer-building {
  font-size: 72px;
}
.app-home-step3-row {
  margin-left: 120px;
}
.app-image-row-mobile {
  width: 1200px;
}

.app-newhome-images3-mob {
  width: 200px;
}
.app-newhome-images3-mob:hover {
  width: 200px;
}
.app-newhome-images1-mob {
  width: 200px;
}
.app-newhome-image2-mob {
  width: 200px;
}

.app-home-videoeditor {
  background-color: rgba(232, 255, 251, 1);
  border-radius: 80px;
  height: 74px;
  width: 74px;
}

.app-image-mob::-webkit-scrollbar {
  display: none;
}
.app-newhome-bgc-makeup-artist {
  background-color: rgba(255, 242, 232, 1);
}

.app-home-bookbtn {
  padding: 4px 8px 4px 8px;
  margin-bottom: -5px;
  font-size: 12px;
  color: rgba(68, 68, 68, 1);
}

.app-newhome-bgc-makeup-artist {
  background-color: rgba(255, 242, 232, 1);
  width: 110px;
}
.app-newhome-bgc-videographer {
  background-color: rgba(243, 232, 255, 1);
  width: 110px;
}
.app-home-comingbtn {
  padding: 4px 8px 4px 8px;
  margin-bottom: -30px;
  font-size: 12px;
  color: rgba(68, 68, 68, 1);
}
.app-home-container-h1-mobile {
  font-size: 32px;
  color: rgba(35, 35, 35, 1);
  line-height: 35.56px;
}
.app-home-container-h2 {
  font-size: 16px;
  color: rgba(99, 99, 99, 1);
  line-height: 24px;
}
.app-home-container-h2-mobile {
  font-size: 16px;
  color: rgba(99, 99, 99, 1);
  line-height: 21px;
}
.app-home-container-btn {
  color: white;
  background-color: rgba(0, 39, 202, 1);
  border-radius: 12px;
  width: 350px;
  min-height: 56px;
  cursor: pointer;
  /* box-shadow: 0px 6px 12px 0px rgba(206, 196, 245, 1); */
}
.app-home-container-btn-mobile {
  color: white;
  background-color: rgba(0, 39, 202, 1);
  border-radius: 12px;
  width: 350px;
  min-height: 56px;
  cursor: pointer;
  box-shadow: 0px 6px 12px 0px rgba(206, 196, 245, 1);
}

.app-newhome-ve {
  background-color: rgba(210, 238, 233, 1);
  border-radius: 80px;
  height: 74px;
  width: 74px;
  font-size: 12px;
  display: flex;
  align-items: center;
  text-align: initial;
  margin-bottom: -30px;
  border: 5px solid white;
}
.app-newhome-ve-text {
  margin-left: -12px;
  white-space: nowrap;
}
.app-imagesrow-mob {
  margin-top: 50px;
}
.app-newhome-vectortop {
  margin-top: 100px;
  margin-bottom: -10px;
}

.app-newhome-images1:hover,
.app-newhome-images2:hover,
.app-newhome-images3:hover {
  margin-top: -20px;
  margin-bottom: 40px;
}
.app-newhome-images1,
.app-newhome-images2,
.app-newhome-images3 {
  margin-top: 40px;
}
.app-home-container-image1,
.app-home-container-image2,
.app-home-container-image3 {
  cursor: pointer;
  border-radius: 16px;
  transition: transform 0.3s ease;
  opacity: 1;
}
/* .app-home-container-image1:hover,
.app-home-container-image2:hover,
.app-home-container-image3:hover {
  cursor: pointer;
  border-radius: 24px;
  transition: width 0.3s ease;
  width: auto; */
/* margin-top: -30px;
  margin-bottom: 30px; */
/* position: relative;
} */
.app-home-container-startbtn {
  color: white;
  max-width: 212px;
  margin-bottom: 20px;
  background-color: #0027ca;
  border-radius: 12px;
  cursor: pointer;
  padding: 6px 10px 6px 10px;
}

.app-home-container-image2-mobile {
  padding-left: 5%;
}

.app-influencerjobpage-about {
  font-size: 12px;
  color: #787878;
}

.app-influencerjobpage-card1-date {
  font-size: 14px;
  border-radius: 8px;
  max-width: 200px;
  background-image: linear-gradient(
    rgba(255, 242, 247, 1),
    rgba(246, 243, 255, 1)
  );
  padding: 6px 8px 6px 8px;
}
.app-influencerjobpage-cards-date {
  font-size: 12px;
  border-radius: 8px;
  max-width: 200px;
  border-color: #f5f5f5;
  background-color: #f5f5f5;
  padding: 6px 8px 6px 8px;
}
.app-home-background,
.app-influencerjobpage-card1-paid {
  font-size: 14px;
  border-radius: 8px;
  background-image: linear-gradient(
    rgba(255, 242, 247, 1),
    rgba(246, 243, 255, 1)
  );
  padding: 6px 8px 6px 8px;
}
.app-influencerjobpage-cards-paid {
  font-size: 12px;
  border-radius: 8px;
  max-width: 205px;
  border-color: #f5f5f5;
  background-color: #f5f5f5;
  padding: 6px 8px 6px 8px;
}
.app-influencerjobpage-card1-gender {
  font-size: 14px;
  border-radius: 8px;
  max-width: 68px;
  border-color: #f5f5f5;
  background-color: #f5f5f5;
  padding: 6px 8px 6px 8px;
}
.app-influencerjobpage-cards-gender {
  font-size: 12px;
  border-radius: 8px;
  max-width: 68px;
  border-color: #f5f5f5;
  background-color: #f5f5f5;
  padding: 6px 8px 6px 8px;
}
.app-influencerjobpage-card2-payment {
  font-size: 11px;
}
.app-jobpage-card2-btn2 {
  width: 100%;
  height: 56px;
  background-color: #0027ca;
  border: transparent;
  padding: 12px 24px 12px 24px;
}
.app-jobpage-card2-btn {
  border: 1px solid #0027ca;
  background-color: transparent !important;
  border-radius: 8px;
  height: 56px;
  max-width: 119px;
  /* color: #0027ca; */
}
.app-home-footer-copyright,
.app-jobfeed-footer-copyright {
  font-size: 9px;
}
.app-jobpage-card2-btn:hover {
  border: 1px solid #0027ca;
  background-color: transparent !important;
  border-radius: 8px;
  height: 56px;
  max-width: 119px;
}
.app-influencerjobpage-responsetime {
  background-color: #fff2f7;
  color: #232323;
  margin-top: -10px;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  font-size: 12px;
}
.app-influencerjobpage-cardcollection {
  background-color: #ffffff;
  border-radius: 16px;
  border: none;
  box-shadow: 0px 2px 12px 0 rgba(0, 0, 0, 0.1);
}
.app-jobpage-card2-save {
  color: #0027ca;
}
@media (max-width: 767.98px) {
  .app-border-sm-start {
    border-left: none !important;
  }
}

/* create job */

.app-job-create-AboutCompany {
  border: 1px solid #f0f0f0;
  border-radius: 8px;
  min-height: 48px;
  width: 100%;
  color: #444444;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}
.app-job-create-AboutCompany-flag {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #ed6c9f;
  background-color: #ffffff;
  color: #ed6c9f;
  display: flex;
  font-size: 11px;
  align-items: center;
  justify-content: center;
  padding: 0px;
}
.app-job-create-AboutCompany-flag2 {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid rgba(161, 161, 161, 1);
  background-color: #ffffff;
  display: flex;
  color: rgba(161, 161, 161, 1);
  font-size: 11px;
  align-items: center;
  justify-content: center;
  padding: 0px;
}

.app-job-create-addLogo {
  color: #636363;
}
.app-job-create-addLogoImage {
  height: 56px;
  width: 56px;
  background-color: #b0afaf;
  border-radius: 30px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.app-job-create-Card {
  border-radius: 16px;
  height: 100%;
  /* height: 535px; */
  color: #232323;
  background-color: #ffffff;
  /* border: 1px solid #ffffff; */
}
.app-job-create-button {
  background: var(--main-color);
  width: 100%;
  height: 56px;
  border: 1px solid var(--main-color);
  border-radius: 8px;
  color: #fff;
}
.app-jobcreate-select-category-button {
  max-width: 326px;
}
.app-job-create-form-input {
  min-height: 48px;
  width: 100%;
  font-size: 14px;
  border-radius: 8px;
  border-color: #f5f5f5;
  background-color: #f5f5f5;
}
.app-job-create-form-input-datetime {
  min-height: 48px;
  /* max-width: 232px; */
  font-size: 14px;
  border-radius: 8px;
  border-color: #f5f5f5;
  background-color: #f5f5f5;
}
.app-job-create-addPlatformBtn:hover,
.app-job-create-addPlatformBtn {
  background-color: #f5f5f5 !important;
  color: #0027ca !important;
  border: 1px solid #0027ca !important;
}

.app-job-create-addPlatformedit {
  color: #0027ca;
}

.app-job-create-addPlatformadded {
  border-radius: 30px;
  background-color: #f5f5f5;
}
.app-job-create-selectCategory-x {
  background-color: #f5f5f5;
}
.app-home-connect,
.app-home-buildnetwork,
.app-job-create-platform-h1 {
  font-size: 16px;
  /* font-weight: 500; */
  font-family: "poppins";
}

.app-job-create-mobile-CategoryCard {
  /* background: none; */
  background-color: #ffffff;
  /* border-top-right-radius: 24px;
  border-top-left-radius: 24px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px; */
  border: none;
  width: 100%;
}

.app-job-create-selectCategory,
.app-appbar-title {
  font-size: 18px;
  font-family: "poppins";
  color: #0027ca;
}
.app-job-create-h3 {
  font-size: 16px;
  color: #232323;
}
.app-job-created-jobTimeline {
  border: 1px solid #e2e2e2;
  border-radius: 4px;
}
.app-job-create-lastCard {
  height: 400px;
  background-color: #ffffff;
  border-radius: 16px;
}
.app-job-create-lastCard-icon {
  color: #d63370;
}
.app-job-create-DesktopCard {
  max-width: 700px;
  max-height: 596px;
}
.app-job-create-AboutCompany-1-check {
  background-color: #d63370;
  color: #ffffff;
}

/* followers count and engagement card CSS */

.app-job-create-followers-count {
  font-size: 18px;
}

.price-input {
  width: 100%;
  display: flex;
}

.price-input .field {
  display: flex;
  height: 45px;
  align-items: center;
}

.field input {
  width: 100%;
  height: 100%;
  font-size: 19px;
  border-radius: 5px;
  border: none;
  text-align: center;
  background-color: #f5f5f5;
}

.slider {
  height: 5px;
  position: relative;
  border-radius: 5px;
}

.slider .progress {
  height: auto; /* or specify a height if needed */
  background-color: transparent !important;
  left: 0;
  right: 0;
  /* position: absolute; */
  border-radius: 5px;
}

.range-input {
  position: relative;
}

.range-input input {
  position: absolute;
  border-radius: 8px;
  height: 8px;
  background: #8a4444;
  pointer-events: none;
  /* -webkit-appearance: none;
  -moz-appearance: none; */
}
input[type="range"]::-webkit-slider-thumb {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background: #000000;
  pointer-events: auto;
  -webkit-appearance: none;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.05);
}

.price-input-custom {
  width: 100%;
  display: flex;
}

.price-input-custom .field {
  display: flex;
  height: 45px;
  align-items: center;
}

.field input {
  width: 100%;
  height: 100%;
  font-size: 19px;
  border-radius: 5px;
  border: none;
  text-align: center;
  background-color: #f5f5f5;
}

.slider-custom {
  height: 5px;
  position: relative;
  border-radius: 5px;
}

.slider-custom .progress-custom {
  height: auto; /* or specify a height if needed */
  background-color: transparent !important;
  left: 0;
  right: 0;
  border-radius: 5px;
}

.range-input-custom {
  position: relative;
}

.range-input-custom input {
  position: absolute;
  border-radius: 8px;
  height: 8px;
  background: #8a4444;
  pointer-events: none;
}

input[type="range"]::-webkit-slider-thumb {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background: #000000;
  pointer-events: auto;
  -webkit-appearance: none;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.05);
}

/* Influencer Job Create */
.app-job-create-influencer-card {
  max-width: 528px;
  margin: 0 auto;
  padding: 2rem 2rem;
  color: #ffffff;
  border-radius: 16px;
  background-color: #ffffff;
  border: 1px solid #f0f0f0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.app-job-create-sub-heading {
  font-size: 12px;
  color: #787878;
}
.app-job-create-form-label {
  font-size: 12px;
  color: #232323;
}
.app-influencerjobcreate-container {
  color: #636363;
}
.app-influencerjobcreate-btns {
  background-color: #f5f5f5;
  border-radius: 8px;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 25px;
  padding-right: 6px;
}
.app-chatcomponent-chat-container {
  height: 400px;

  /* display: flex; */
  /* flex-direction: column; */

  /* padding: 4px; */
  /* box-sizing: border-box; */
  width: 100%;
  /* border: 1px solid #ddd; */
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0px 0px 6px 0px rgb(138, 117, 149);
}
.app-chatanimation-message-sent {
  display: flex;
  background-color: #dcf8c6;
  align-items: center;
  /* margin-bottom: 5px; */
  max-width: 50%;
  padding-left: 10px;
  border-radius: 10px;
  margin-left: 100px !important;
}
.cursorpointer {
  cursor: pointer;
}
.messages::-webkit-scrollbar {
  width: 6px;
}
.messages::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 10px;
}
.app-chatanimation-message-sent {
  text-align: right;
  margin: 5px 0;
  background: #a8e1c8;
  padding-top: 4px;
  border-radius: 10px;
  max-width: 70%;
  align-self: flex-end;
}
.app-chatcomponent-message-received {
  text-align: left;
  margin: 5px 0;
  background: #8e8a8a;
  padding: 2px;
  border-radius: 10px;
  max-width: 70%;
  align-self: flex-start;
}
.app-joblist-messaging-before {
  background-color: #d63370;
}
.app-chatcomponent-header {
  background-color: #d63370;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}
.app-chatcomponent-chat-input input {
  flex: 1;
  border: 1px solid #ddd;
}
.app-joblist-sender-list-section {
  overflow-y: auto;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  box-shadow: 0px 6px 12px 0px rgb(138, 117, 149);
}
.app-chatanimation-message-sent {
  text-align: right;
  color: rgb(255, 255, 255);
}
.app-joblist-username-color {
  color: #d63370;
}
.app-joblist-uselist-width {
  width: 20%;
}
.app-chatanimation-slide-up {
  transform: translateY(0);
  opacity: 1;
  transition: transform 0.5s ease, opacity 0.5s ease;
}
.app-chatanimation-slide-down {
  transform: translateY(100%);
  opacity: 0;
  transition: transform 0.5s ease, opacity 0.5s ease;
}
.app-homepage-container-h1-mobile {
  font-size: 40px;
}
.app-homepage-container-h2-mobile {
  font-size: 18px;
  line-height: 27px;
}
.app-homepage-container-h1 {
  font-size: 40px;
}
.app-homepage-container-h2 {
  font-size: 18px;
  line-height: 27px;
  color: rgba(99, 99, 99, 1);
}
.app-HomePage-logorow {
  margin-bottom: 15vh;
}
.app-homepage-background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
  opacity: 1;
  padding: 0;
}

.app-homepage-container-h1-mobile,
.app-homepage-container-h2-mobile,
.app-home-container-btn-mobile {
  position: relative;
  z-index: 1;
  color: rgba(255, 255, 255, 1);
}
.app-homepage-video-container {
  position: relative;
  width: 100%;
  padding-bottom: 120.77%;
  height: 0;
  overflow: hidden;
}

.app-honmepage-responsive-video {
  position: absolute;
  border-radius: 24px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
